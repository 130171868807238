import { useState, useEffect } from "react";

const useVideo = (source) => {
  const [iframe, setIframe] = useState(false);
  const [id, setId] = useState(null);

  function parseMediaURL(source) {
    let regexp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/i;
    let url = source;
    let match = url.match(regexp);
    return match && match[7].length === 11 ? match[7] : false;
  }
  //regexp get from here ( 2 answer ) https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url

  useEffect(() => {
    setId(() => parseMediaURL(source));
  }, [source]);

  const playVideo = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIframe(true);
  };

  //poster for new browser format webp
  const poster_chrome = `https://i.ytimg.com/vi_webp/${id}/maxresdefault.webp`;
  //poster for old browser format jpg
  const poster_youtube = `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`;
  //embed link video
  const embed_link = `https://www.youtube.com/embed/${id}?rel=0&showinfo=0&autoplay=1`;

  return { poster_chrome, poster_youtube, embed_link, iframe, id, playVideo };
};

export { useVideo };
