import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import { ThankBonusMap } from "../components/ThankBonusMap";
import { ThankVideo } from "../components/ThankVideo";

const ThankPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="Description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <ThankVideo {...props.thankVideo} />
      <ThankBonusMap {...props.bonusMap} />
    </>
  );
};

ThankPageTemplate.getLivePreviewData = (props) => ({
  ...props.data,
  ...Layout.getLivePreviewData(props),
  ...ThankVideo.getLivePreviewData(props),
  ...ThankBonusMap.getLivePreviewData(props),
});

const ThankPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      thankPage
      {...data.layout.frontmatter}
      pageId={frontmatter.pageId}
      path={frontmatter.path}
      templateKey={frontmatter.templateKey}
    >
      <ThankPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query ThankPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "thank-page" } }) {
      frontmatter {
        pageId
        path
        seo {
          title
          description
          keywords
        }
        templateKey
        ...ThankVideoFragment
        ...ThankBonusMapFragment
      }
    }
  }
`;

export default ThankPage;

export { pageQuery, ThankPageTemplate };
