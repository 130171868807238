import React from "react";
import { PlayButton } from "./PlayButton";
import cn from "classnames";
import { useVideo } from "./useVideo";
import "./styles.css";

export const Video = ({ className, source }) => {
  const {
    poster_chrome,
    poster_youtube,
    embed_link,
    iframe,
    playVideo,
  } = useVideo(source);

  const videoClassNames = cn({
    Video__video: true,
  });

  return (
    <div
      onClick={playVideo}
      className={`[ ${className} ] [ ${videoClassNames} ]`}
    >
      {iframe && (
        <iframe
          title="Видео"
          src={embed_link}
          className="Video__media"
          frameBorder="0"
          allow="autoplay"
          allowFullScreen={true}
        >
          Ваш браузер не поддерживает iframe
        </iframe>
      )}

      {!iframe && (
        <>
          <a className="Video__link" href={source}>
            <picture>
              <source srcSet={poster_chrome} type="image/webp" />
              <img className="Video__media" src={poster_youtube} alt="" />
            </picture>
          </a>
          <PlayButton className="Video__play" />
        </>
      )}
    </div>
  );
};
