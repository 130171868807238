import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import "./styles.css";

const ThankBonusMap = ({ title, text }) => {
  const [mapSrc, setMapSrc] = useState("");
  useEffect(() => {
    window.onload = function() {
      setMapSrc("https://yandex.by/map-widget/v1/-/CCQpUWUEwC");
    };

    setTimeout(
      () => setMapSrc("https://yandex.by/map-widget/v1/-/CCQpUWUEwC"),
      15000
    );
  }, []);

  return (
    <div className="ThankBonusMap">
      <div className="[ Content  md:Content_small  sm:Content_medium ]">
        <div className=" [ ThankBonusMap__title ] [ Font_headerBig sm:Font_headerSmall  Color_darkBlue ]">
          {title}
        </div>
        <div className=" [ ThankBounusMap__text ] [ Font_textRegular Color_darkBlue ]">
          {text}
        </div>

        <div className="ThankBounusMap__map">
          {mapSrc ? (
            <iframe title="Карта" src={mapSrc} frameBorder="0" />
          ) : (
            <div className="Footer__mapLoader" />
          )}
        </div>
      </div>
    </div>
  );
};

ThankBonusMap.getLivePreviewData = ({ data, getAsset }) => ({
  bonusMap: {
    ...data.bonusMap,
  },
});

const thankBonusMapQuery = graphql`
  fragment ThankBonusMapFragment on MarkdownRemarkFrontmatter {
    bonusMap {
      title
      text
    }
  }
`;

export { ThankBonusMap, thankBonusMapQuery };
