import { navigate } from "@reach/router";
import { graphql, Link } from "gatsby";
import React from "react";
import arrow from "./arrow.svg";
import { InfoBlock } from "./InfoBlock";
import "./styles.css";
import { Video } from "./Video";

const ThankVideo = ({
  link,
  thankMessage,
  buttonLabel,
  contactsLabel,
  phoneNumber,
  email,
  socialNetworks,
  addressLabel,
  address,
  workTimeLabel,
  workTime,
}) => {
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="[ ThankVideo  ] [ Content  md:Content_small  sm:Content_medium ]">
      <Link
        className="[ ThankVideo__backLink ] [ Font_textSemiBold ]"
        onClick={goBack}
      >
        <img src={arrow} alt="" />
        <div>{buttonLabel}</div>
      </Link>

      <div className="ThankVideo__wrapper">
        <div className="ThankVideo__info">
          <div className="[ Font_headerMiddle sm:Font_headerSmall Color_darkBlue ]">
            {thankMessage}
          </div>

          <Video className="ThankVideo__video video_mobile" source={link} />

          <div className="ThankVideo__contacts">
            <div className="ThankVideo__contacts_block">
              <InfoBlock label={contactsLabel} items={[phoneNumber, email]} />

              <ul className="ThankVideo__socialIcons">
                {socialNetworks.map(({ icon, link }) => (
                  <li>
                    <a href={link}>
                      <img src={icon.publicURL} alt="" />
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="ThankVideo__contacts_block">
              <InfoBlock label={addressLabel} items={[address]} />
              <InfoBlock label={workTimeLabel} items={[workTime]} />
            </div>
          </div>
        </div>

        <Video className="ThankVideo__video video_desktop" source={link} />
      </div>
    </div>
  );
};

ThankVideo.getLivePreviewData = ({ data, getAsset }) => ({
  thankVideo: {
    ...data.thankVideo,
    socialNetworks: data.thankVideo.socialNetworks.map((socialNetwork) => ({
      ...socialNetwork,
      icon: { publicURL: getAsset(socialNetwork.icon.publicURL) },
    })),
  },
});

const thankVideoQuery = graphql`
  fragment ThankVideoFragment on MarkdownRemarkFrontmatter {
    thankVideo {
      link
      thankMessage
      buttonLabel
      text
      contactsLabel
      phoneNumber
      email
      socialNetworks {
        icon {
          publicURL
        }
        link
      }
      addressLabel
      address
      workTimeLabel
      workTime
    }
  }
`;

export { ThankVideo, thankVideoQuery };
