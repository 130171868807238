import React from "react";
import { paragraph } from "../../utils/paragraph";

export const InfoBlock = ({ label, items }) => {
  return (
    <div className="[ThankVideo__contacts ] [ InfoBlock ]">
      <div className="[ Font_textSemiBold Color_darkBlue48 ]">{label}</div>
      {items.map((item) => (
        <div
          className="[ Font_smallText  Color_darkBlue ]"
          dangerouslySetInnerHTML={{ __html: paragraph(item) }}
        />
      ))}
    </div>
  );
};
