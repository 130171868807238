import React from "react";

export const PlayButton = ({ className }) => {
  return (
    <button aria-label="Запустить видео" className="PlayButton__button">
      <svg
        className={className}
        width="113"
        height="112"
        viewBox="0 0 113 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          className="PlayButton__button_ellipse"
          cx="56.1833"
          cy="55.9197"
          rx="55.8493"
          ry="55.7366"
        />
        <path
          className="PlayButton__button_triangle"
          d="M76.0003 55.9194L47.1748 36.1419V75.6969L76.0003 55.9194Z"
        />
      </svg>
    </button>
  );
};
